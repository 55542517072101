export default [
    {
      "date": "1584921600",
      "outlet": "New York Times",
      "state": "national / NY",
      "title": "Feeling Powerless About Coronavirus? Join a Mutual-Aid Network",
      "mah_mentioned": "yes",
      "link": "https://www.nytimes.com/2020/03/23/opinion/coronavirus-aid-group.html",
      "reporter": "Charlie Warzer",
      "contact_info": "Can't find email",
      "": "",
      "__1": ""
    },
    {
      "date": "1596746894",
      "outlet": "Teen Vogue",
      "state": "national",
      "title": "Mutual Aid Organizers Want Congress to Extend Pandemic Unemployment Assistance",
      "mah_mentioned": "yes",
      "link": "https://www.teenvogue.com/story/mutual-aid-organizers-congress-pandemic-unemployment-assistance",
      "reporter": "Lucy Diavolo",
      "contact_info": "",
      "": "",
      "__1": ""
    },
     {
      "date": "1614807849",
      "outlet": "New York Times",
      "state": "national",
      "title": "How Neighborhood Groups Are Stepping In Where the Government Didn’t",
      "mah_mentioned": "yes",
      "link": "https://www.nytimes.com/2021/03/03/nyregion/covid-19-mutual-aid-nyc.html",
      "reporter": "Kimiko de Freytas-Tamura",
      "contact_info": "",
      "": "",
      "__1": ""
    },
    {
      "date": "1585785600",
      "outlet": "America Magazine",
      "state": "national",
      "title": "How to build mutual aid that will last after the Coronavirus pandemic",
      "mah_mentioned": "yes",
      "link": "https://www.americamagazine.org/politics-society/2020/04/01/how-build-mutual-aid-will-last-after-coronavirus-pandemic",
      "reporter": "Nathan Schneider",
      "contact_info": "Nathan.Schneider@Colorado.edu",
      "": "",
      "__1": ""
    },
    {
      "date": "1585612800",
      "outlet": "Chicago Public Library",
      "state": "IL",
      "title": "Mutual Aid: Supporting Community Members in Need",
      "mah_mentioned": "yes",
      "link": "https://www.chipublib.org/blogs/post/mutual-aid-supporting-community-members-in-need/",
      "reporter": "Sasha - blog contributor",
      "contact_info": "Can't find email",
      "": "",
      "__1": ""
    },
    {
      "date": "1585872000",
      "outlet": "Chicago Daily Herald",
      "state": "IL",
      "title": "Wheaton mutual aid group helping those most hurt by economic fallout",
      "mah_mentioned": "yes",
      "link": "https://www.dailyherald.com/news/20200402/wheaton-mutual-aid-group-helping-those-most-hurt-by-economic-fallout",
      "reporter": "Katlyn Smith",
      "contact_info": "ksmith@dailyherald.com",
      "": "",
      "__1": ""
    },
    {
      "date": "1586217600",
      "outlet": "Kansas City Star",
      "state": "MO",
      "title": "Your money, your blood, your time: How to help Kansas City during COVID-19 crisis",
      "mah_mentioned": "yes",
      "link": "https://www.kansascity.com/news/coronavirus/article241807581.html",
      "reporter": "Dan Kelly",
      "contact_info": "dkelly@kcstar.com",
      "": "",
      "__1": ""
    },
    {
      "date": "1586476800",
      "outlet": "Mapbox blog",
      "state": "national",
      "title": "Creativity and compassion during COVID-19",
      "mah_mentioned": "yes",
      "link": "https://blog.mapbox.com/creativity-and-compassion-in-covid-19-ed3c0fd54c5d",
      "reporter": "Marena Brinkhurst",
      "contact_info": "Can't find email",
      "": "",
      "__1": ""
    },
    {
      "date": "1586736000",
      "outlet": "Refinery 29",
      "state": "NY",
      "title": "How To Help New York City & State During Coronavirus",
      "mah_mentioned": "yes",
      "link": "https://www.refinery29.com/en-us/2020/04/9684043/how-to-help-new-york-coronavirus-covid-19",
      "reporter": "Natalie Goncharova",
      "contact_info": "Can't find email",
      "": "",
      "__1": ""
    },
    {
      "date": "1588118400",
      "outlet": "Street Fight",
      "state": "national",
      "title": "AARP Launches Platform Empowering Neighbors to Assist Each Other during Pandemic",
      "mah_mentioned": "yes",
      "link": "https://streetfightmag.com/2020/04/29/aarp-launches-platform-empowering-neighbors-to-assist-each-other-during-pandemic/#.XqsmVilKhhE",
      "reporter": "Stephanie Miles",
      "contact_info": "Can't find email",
      "": "",
      "__1": ""
    },
    {
      "date": "1587686400",
      "outlet": "Spectrum Local News",
      "state": "NY",
      "title": "PUSH Buffalo Sets Up Mutual Aid Hub",
      "mah_mentioned": "no",
      "link": "https://spectrumlocalnews.com/nys/buffalo/news/2020/04/25/push-buffalo-sets-up-mutual-aid-hub-",
      "reporter": "Brandon Lewis",
      "contact_info": "brandon.s.lewis@charter.com",
      "": "",
      "__1": ""
    },
    {
      "date": "1587686400",
      "outlet": "Commune Mag",
      "state": "national",
      "title": "Organizing for Survival in New York City",
      "mah_mentioned": "no",
      "link": "https://communemag.com/two-months-of-mutual-aid-in-queens/",
      "reporter": "N/A",
      "contact_info": "Editor@communemag.com",
      "": "",
      "__1": ""
    },
    {
      "date": "1588032000",
      "outlet": "Vice",
      "state": "national",
      "title": "Mutual Aid Networks are Distributing Food with Tech That Works For Everyone",
      "mah_mentioned": "no",
      "link": "https://www.vice.com/en_us/article/g5xzg9/mutual-aid-networks-are-distributing-food-with-tech-that-works-for-everyone",
      "reporter": "Janus Rose",
      "contact_info": "Can't find email",
      "": "",
      "__1": ""
    },
    {
      "date": "1584662400",
      "outlet": "Democracy Now",
      "state": "national",
      "title": "Solidarity Not Charity: Mutual Aid and How to Organize in the Age of Coronavirus",
      "mah_mentioned": "yes",
      "link": "https://www.democracynow.org/2020/3/20/coronavirus_community_response_mutual_aid",
      "reporter": "Amy Goodman",
      "contact_info": "Can't find a general email for Democracy Now",
      "": "",
      "__1": ""
    },
    {
      "date": "1584316800",
      "outlet": "Teen Vogue",
      "state": "national",
      "title": "People are Fighting the Coronavirus with Mutual Aid Groups to Help Each Other",
      "mah_mentioned": "no",
      "link": "https://www.teenvogue.com/story/people-fighting-coronavirus-mutual-aid-efforts-help-each-other",
      "reporter": "Lucy Diavolo",
      "contact_info": "lucy_diavolo@condenast.com",
      "": "",
      "__1": ""
    },
    {
      "date": "1588291200",
      "outlet": "Wesleyan Argus",
      "state": "Middletown, CT",
      "title": "Middletown Mutual Aid Collective Formed to Meet Needs of Middleton Community",
      "mah_mentioned": "no",
      "link": "http://wesleyanargus.com/2020/05/01/middletown-mutual-aid-collective-formed-to-meet-needs-of-middletown-community/",
      "reporter": "Hallie Sternberg",
      "contact_info": "(Hallie's email isn't on the website, but here's the email of the editor-in-chief: kdyja@wesleyan.edu)",
      "": "",
      "__1": ""
    },
    {
      "date": "1587340800",
      "outlet": "Eater",
      "state": "national",
      "title": "With Online Spreadsheets, Mutual Aid Networks are Keeping People Fed During Coronavirus",
      "mah_mentioned": "no",
      "link": "https://www.eater.com/2020/4/20/21221588/mutual-aid-networks-coronavirus-resources-food-meals-groceries-delivery-covid-19",
      "reporter": "Brittany Helena Young",
      "contact_info": "Can't find email",
      "": "",
      "__1": ""
    },
    {
      "date": "1587081600",
      "outlet": "Washington Post",
      "state": "national",
      "title": "People Across the Country Are Delivering Groceries Free. It's \"Solidarity, Not Charity",
      "mah_mentioned": "no",
      "link": "https://www.washingtonpost.com/lifestyle/2020/04/27/people-across-country-are-delivering-groceries-free-its-solidarity-not-charity/",
      "reporter": "Cathy Free",
      "contact_info": "@cathyjfree",
      "": "",
      "__1": ""
    },
    {
      "date": "1587081600",
      "outlet": "Yes! Magazine",
      "state": "national",
      "title": "How to get Mutual Aid to Those Most in Need",
      "mah_mentioned": "no",
      "link": "https://www.yesmagazine.org/social-justice/2020/04/17/coronavirus-mutual-aid/",
      "reporter": "Amanda Abrams",
      "contact_info": "manda.abrams@gmail.com",
      "": "",
      "__1": ""
    },
    {
      "date": "1588550400",
      "outlet": "The Bold Italic",
      "state": "CA",
      "title": "San Francisco Has Never Been More Divided — Or United",
      "mah_mentioned": "",
      "link": "https://thebolditalic.com/san-francisco-has-never-been-more-divided-or-united-cb1ab9745bb5?gi=718ea50961a7",
      "reporter": "Fiona Lee",
      "contact_info": "",
      "": "",
      "__1": ""
    },
    {
        "date": "0",
        "outlet": "San Diego Tribune",
        "state": "CA",
        "title": "Local mutual aid group provides community support during COVID-19",
        "mah_mentioned": "",
        "link": "https://www.sandiegouniontribune.com/lifestyle/people/story/2020-04-18/local-mutual-aid-group-provides-community-support-during-covid-19",
        "reporter": "Lisa Deaderick",
        "contact_info": "lisa.deaderick@sduniontribune.com",
        "": "",
        "__1": ""
      },
      {
        "date": "0",
        "outlet": "Boston Magazine",
        "state": "MA",
        "title": "While We’re Forced Apart, Mutual Aid Groups Bring Neighbors Back Together",
        "mah_mentioned": "",
        "link": "https://www.bostonmagazine.com/news/2020/03/25/mutual-aid-groups-coronavirus/",
        "reporter": "Alyssa Vaughn",
        "contact_info": "avaughn@bostonmagazine.com",
        "": "",
        "__1": ""
      },
      {
        "date": "0",
        "outlet": "LA Magazine",
        "state": "CA",
        "title": "‘Solidarity Not Charity’: How L.A.’s ‘Mutual Aid’ Groups Are Creating Community During a Crisis",
        "mah_mentioned": "",
        "link": "https://www.lamag.com/citythinkblog/mutual-aid-covid/",
        "reporter": "Zoie Matthew",
        "contact_info": "zoietmatthew@gmail.com",
        "": "",
        "__1": ""
      },
      {
        "date": "0",
        "outlet": "NY Daily News",
        "state": "NY",
        "title": "Caring New Yorkers increasingly lend a helping hand to neighbors in need as war against coronavirus gets local",
        "mah_mentioned": "",
        "link": "https://www.nydailynews.com/new-york/ny-mutual-aid-groups-nyc-20200421-5tptapzdivdvjbyv44allaicve-story.html",
        "reporter": "Brittany Kriegstein",
        "contact_info": "bkriegstein@nydailynews.com",
        "": "",
        "__1": ""
      },
      {
        "date": "0",
        "outlet": "Vermont Biz",
        "state": "VT",
        "title": "Community response and mutual aid efforts organize across Vermont",
        "mah_mentioned": "",
        "link": "https://vermontbiz.com/news/2020/april/03/community-response-and-mutual-aid-efforts-organize-across-vermont",
        "reporter": "No author listed",
        "contact_info": "Can't find email",
        "": "",
        "__1": ""
      },
      {
        "date": "0",
        "outlet": "CBS 19 News",
        "state": "VA",
        "title": "New mutual aid campaign launches for emergency food relief",
        "mah_mentioned": "",
        "link": "https://www.cbs19news.com/story/42008009/new-mutual-aid-campaign-launches-for-emergency-food-relief",
        "reporter": "News Staff",
        "contact_info": "news@cbs19news.com",
        "": "",
        "__1": ""
      },
      {
        "date": "0",
        "outlet": "Idaho Press",
        "state": "ID",
        "title": "Shining a light: Mutual Aid Group founders share drive to help",
        "mah_mentioned": "",
        "link": "https://www.idahopress.com/community/life/shining-a-light-mutual-aid-group-founders-share-drive-to-help/article_28c8e5ba-40de-5d15-be7e-e034142e0101.html",
        "reporter": "Jeanne Huff",
        "contact_info": "jhuff@idahopress.com",
        "": "",
        "__1": ""
      },
      {
        "date": "0",
        "outlet": "New Haven Independent",
        "state": "CT",
        "title": "Mutual Aid Teams Tackle Covid-19 Challenge",
        "mah_mentioned": "",
        "link": "https://www.newhavenindependent.org/index.php/archives/entry/mutual_aid/",
        "reporter": "Thomas Breen",
        "contact_info": "Can email Thomas through an embedded program on their site",
        "": "",
        "__1": ""
      },
      {
        "date": "0",
        "outlet": "Moab Sun News",
        "state": "UT",
        "title": "‘Great love and care’",
        "mah_mentioned": "",
        "link": "https://www.moabsunnews.com/covid-19/article_bc6f6a06-8191-11ea-93c5-5b3d43787026.html",
        "reporter": "Rachel Fixsen",
        "contact_info": "editor@moabsunnews.com",
        "": "Maggie McGuire",
        "__1": "editor@moabsunnews.com"
      },
      {
        "date": "0",
        "outlet": "Brooklyn Daily Eagle",
        "state": "NY",
        "title": "Mutual aid groups deliver food and meds to vulnerable Brooklynites",
        "mah_mentioned": "yes",
        "link": "https://brooklyneagle.com/articles/2020/04/03/mutual-aid-groups-deliver-food-and-meds-to-vulnerable-brooklynites/",
        "reporter": "Mary Frost",
        "contact_info": "news@brooklyneagle.com",
        "": "",
        "__1": ""
      },
      {
        "date": "0",
        "outlet": "Boston 25 News",
        "state": "MA",
        "title": "Local communities assembling mutual aid groups to help residents in need",
        "mah_mentioned": "yes",
        "link": "https://www.boston25news.com/news/local-communities-assembling-mutual-aid-groups-help-residents-need/XAUOK5STPRCAPDJXZZLST4RFLE/",
        "reporter": "Natalie Rubino",
        "contact_info": "natalie.rubino@boston25.com",
        "": "",
        "__1": ""
      },
    {
      "date": "	1593475200",
      "outlet": "Hola!",
      "state": "national",
      "title": "Ashlee Marie Preston's Latest Mission",
      "mah_mentioned": "yes",
      "link": "https://us.hola.com/lifestyle/20200630fou2jbb5pg/ashlee-marie-preston-you-are-essential-interview",
      "reporter": "0",
      "contact_info": "0",
      "": "",
      "__1": ""
    },
     {
      "date": "1591660800",
      "outlet": "Architectural Digest",
      "state": "national",
      "title": "How to Help Your Local Community in Times of Crisis—and Beyond",
      "mah_mentioned": "yes",
      "link": "https://www.architecturaldigest.com/story/how-to-help-your-local-community-in-times-of-crisis-and-beyond",
      "reporter": "0",
      "contact_info": "0",
      "": "",
      "__1": ""
    },
     {
      "date": "1590969600",
      "outlet": "Pitchfork",
      "state": "national",
      "title": "Resources in the Fight Against Police Brutality and Systemic Racism",
      "mah_mentioned": "yes",
      "link": "https://pitchfork.com/thepitch/resources-in-the-fight-against-police-brutality-and-systemic-racism/",
      "reporter": "0",
      "contact_info": "0",
      "": "",
      "__1": ""
    },
    {
      "date": "1589414400",
      "outlet": "Vice",
      "state": "national",
      "title": "How to Be a Good Neighbor Right Now",
      "mah_mentioned": "no",
      "link": "https://www.vice.com/en_us/article/pkevwm/how-to-help-neighbors-during-coronavirus-social-distancing",
      "reporter": "Rachel Miller",
      "contact_info": "0",
      "": "",
      "__1": ""
    },
     {
      "date": "1594080000",
      "outlet": "Shareable",
      "state": "national",
      "title": "How to start a mutual aid network in a pandemic",
      "mah_mentioned": "yes",
      "link": "https://www.shareable.net/how-to-start-a-mutual-aid-network-in-a-pandemic/",
      "reporter": "Rachel Miller",
      "contact_info": "0",
      "": "",
      "__1": ""
    },
    {
      "date": "1594080000",
      "outlet": "Shareable",
      "state": "national",
      "title": "How to start a mutual aid network in a pandemic",
      "mah_mentioned": "yes",
      "link": "https://www.shareable.net/how-to-start-a-mutual-aid-network-in-a-pandemic/",
      "reporter": "Rachel Miller",
      "contact_info": "0",
      "": "",
      "__1": ""
    },
    {
        "date": "0",
        "outlet": "The Atlantic",
        "state": "national",
        "title": "Pandemic Organizers Are Co-opting Productivity Software",
        "mah_mentioned": "no",
        "link": "https://www.theatlantic.com/technology/archive/2020/05/coronavirus-mutual-aid-groups-slack-airtable-google/612190/",
        "reporter": "Kaitlyn Tiffany",
        "contact_info": "ktiffany@theatlantic.com",
        "": "",
        "__1": ""
      },
      {
        "date": "0",
        "outlet": "NBC News",
        "state": "national",
        "title": "Communities rally around one another — and Google Docs — to bring coronavirus aid",
        "mah_mentioned": "no",
        "link": "https://www.nbcnews.com/tech/tech-news/communities-rally-around-each-other-google-docs-bring-coronavirus-aid-n1164126",
        "reporter": "April Glaser",
        "contact_info": "0",
        "": "",
        "__1": ""
      },
  ]
  
